@import '@/styles/_shared.scss';

:global(.article-cooking-class-hero) {
  .email-signup {
    button {
      max-width: 222px;
      min-width: auto;
      margin: 0;
    }
  }
}

:global(.article-sidebar) {
  .email-signup__text-success {
    display: block;
    text-align: left;
  }
}

.email-signup {
  width: 100%;
  height: 100%;
  h2 {
    margin-bottom: 20px;
  }
  input {
    border: 1px solid $fawn50-2;
    border-radius: 12px;
    width: 100%;
    height: 48px;
    padding: 20px;
    margin: 0;
    @include breakpoint-up('sm') {
      height: 60px;
    }
  }
  svg {
    position: absolute;
    top: calc(50% - 14px);
    right: 20px;
  }
  button {
    height: 48px;
    min-width: auto;
    @include breakpoint-up('md') {
      height: 62px;
      max-width: 150px;
    }
    @include breakpoint-up('lg') {
      max-width: 200px;
    }
  }
}

.email-signup__disclaimer {
  font-size: 12px;
  margin: 10px 0 0 36px;
}

.email-signup__text-success {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.1;
  margin: 0;
  text-align: center;
  width: 100%;
  height: 100%;
}

.email-signup__text-error {
  font-size: 16px;
  line-height: 1.1;
  margin: 10px 0 0;
  color: $error;
}

.productFooterForm {
  .productFooterInput {
    border-radius: 5px !important;
    margin-bottom: 0;
    height: 50px;

    @include breakpoint-up('md') {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .productFooterButton {
    background-color: $salmon !important;
    border-radius: 5px !important;
    color: #fff;
    margin: 0 auto;
    font-size: 16px;
    height: 50px;

    @include breakpoint-up('md') {
      margin-bottom: 25px;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      max-width: 150px;
      margin-bottom: 12px;
    }

    @include breakpoint-up('lg') {
      margin-bottom: 25px;
    }
  }

  @include breakpoint-up('md') {
    display: flex;
    align-items: center;

    .input-group {
      display: flex;
      width: 100%;

      input {
        flex: 1;
        margin-right: 10px;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0;
        margin-bottom: 0;
      }

      button {
        flex-shrink: 0;
      }
    }
  }
}