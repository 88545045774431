// ===========================================================================
// *** Color variables
// ===========================================================================

// As you add more colors here, create a new UI Kit color swatch and
// optionally update `global/_color-helpers.scss` background color classes.
// For color naming, use http://chir.ag/projects/name-that-color/

// Primary Colors
$sitkablue: #163144;
$sitkabluelight: #3c597d;
$fawn50: #fcfaf8;
$fawn50-2: #f6f3ed;
$alabaster: #fcfaf8;
$alabaster2: #fffdfc;
$copper: #af5d2f;
// $salmon: #AF4C2E;
$salmon: #d18357;

// Secondary Colors
$hherbs: #3c597d;
$fawn: #eae0d2;
$yeller: #af5d2f;
$pureWhite: #ffffff;
$green: #b7cd96;
$green2: #467c52;
$newYeller: #ffbb44;

// Warm Black
$black: #2d2b26;
$black20: #d5d5d4;
$black10: #eaeae9;

// Gray Scale
$gray-900: #212121;
$gray-800: #424242;
$gray-700: #616161;
$gray-600: #757575;
$gray-500: #9e9e9e;
$gray-400: #bdbdbd;
$gray-300: #e0e0e0;
$gray-200: #eeeeee;
$gray-100: #f5f5f5;
$gray-50: #fafafa;

//  Utility UI Colors
$error: #c04a30;
$caution: #d8a82d;
$success: #467c52;
